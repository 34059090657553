export default {
    data() {
        return {
            params: {
                signCallback: signCallback, // 鉴权函数
                // 用户参数
                secretid: config.secretId,
                appid: config.appId,
                // 实时识别接口参数
                engine_model_type: "16k_zh", // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
                // 以下为非必填参数，可跟据业务自行修改
                voice_format: 1,
                hotword_id: "08003a00000000000000000000000000",
                needvad: 1,
                filter_dirty: 1,
                filter_modal: 2,
                filter_punc: 0,
                convert_num_mode: 1,
                word_info: 2,
            },
            resultText: "",
            resultText1: '',
            webAudioSpeechRecognizer: null,
        };
    },
    mounted() { },
    methods: {
        start() {
            this.webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(this.params);
            let resultText = "";
            this.resultText = ""
            let resultText1 = "";
            this.resultText1 = ""
            // 开始识别
            this.webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
                console.log("开始识别", res);
            };
            // 一句话开始
            this.webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
                console.log("一句话开始", res);
            };
            // 识别变化时
            this.webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
                console.log("识别变化时", res);
                resultText1 = res.voice_text_str;
                this.resultText1 = resultText1;
            };
            // 一句话结束
            this.webAudioSpeechRecognizer.OnSentenceEnd = (res) => {
                console.log("一句话结束", res);
                resultText = res.voice_text_str;
                this.resultText = resultText;
            };
            // 识别结束
            this.webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
                console.log("识别结束", res);
            };
            // 识别错误
            this.webAudioSpeechRecognizer.OnError = (res) => {
                console.log("识别失败", res);
            };
            this.webAudioSpeechRecognizer.start();
        },
        end() {
            this.webAudioSpeechRecognizer.stop();
        },
    },
}