<template>
  <div class="demo2">
    <div class="demo_left">
      <div id="rootdemo2"></div>
      <div
        v-loading="loading"
        element-loading-text="生成中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="videobox"
      >
        <video v-show="videoUrl" :src="videoUrl" autoplay controls></video>
      </div>
    </div>
    <div class="demo_right">
      <div class="righttop">
        <el-input
          class="ipt"
          type="textarea"
          v-model="text"
          placeholder="输入文字生成视频"
          @focus="sirFocus"
          @blur="sirBlur"
        ></el-input>
        <el-button type="primary" @click="createAdd">生成视频</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="huida"
          >回答问题</el-button
        >
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="interruptSpeak"
          >停止回答</el-button
        >
         <el-button
          style="margin-left: 30px"
          type="primary"
          @click="Speaktext"
          >说话</el-button
        >
      </div>
      <div>{{ answer }}</div>
    </div>
  </div>
</template>

<script>
import { generate, getDigitalMan, queAnswers } from "@/api";
import VoiceToText from "./VoiceToText";
export default {
  mixins: [VoiceToText],
  data() {
    return {
      answer: "",
      text: "",
      loading: false,
      videoUrl: "",
      timers: null,
      player: null,
      keyFlag: false,
      plugin: window["@shuwen/rtc-plugin"],
      speakList: [
        {
          type: "left",
          text: "是大富大贵发生大范德萨发多少发顺丰发的啥饭撒123213123的法国反对发顺丰发大水发的啥饭撒",
        },
        {
          type: "left",
          text: "是大富大贵发生大范德萨发多少发顺丰发的啥饭撒123213123的法国反对发顺丰发大水发的啥饭撒",
        },
        {
          type: "right",
          text: "是大富大贵发生大范德萨发多少发顺丰发的啥饭撒123213123的法国反对发顺丰发大水发的啥饭撒",
        },
        {
          type: "left",
          text: "是大富大贵发生大范德萨发多少发顺丰发的啥饭撒123213123的法国反对发顺丰发大水发的啥饭撒",
        },
      ],
      iptFocus: false,
      accessToken: "",
    };
  },
  watch: {
    resultText(val) {
      this.text = val;
      this.huida();
    },
  },
  mounted() {
    this.init();
    this.keydown();
  },
  methods: {
    interruptSpeak() {
      this.player.configData.instance.wsInstance.send(
        JSON.stringify({
          vhostToken: this.accessToken, // 用户认证
          uuid: "speak", // 唯一，不同消息区分
          msgType: 12, // 请求打通连接
          data: {
            data: {
              message_type: "interrupt",
            },
          },
        })
      );
    },
    sirFocus() {
      this.iptFocus = true;
    },
    sirBlur() {
      this.iptFocus = false;
    },
    keydown() {
      document.onkeydown = (event) => {
        if (this.iptFocus) {
          return;
        }
        if (event.keyCode === 32) {
          if (!this.keyFlag) {
            this.keyFlag = true;
            this.start();
          }
        }
      };
      document.onkeyup = (event) => {
        if (this.iptFocus) {
          return;
        }
        if (event.keyCode === 32) {
          this.keyFlag = false;
          this.end();
        }
      };
    },
    init() {
      this.plugin
        .getToken({
          ak: "18b55377ee2c4f3daf59a389bd9c527d",
          username: "root",
          password: "heheheh",
          endpoint: location.host, // 域名
        })
        .then((res) => {
          if (res && res.data && res.success) {
            this.accessToken = res.data.vhostToken;
            this.player = new this.plugin.RtcPlayer({
              // pos: ["0", "0"], // 位置： x y
              // size: ["500px", "500px"], // 大小： 宽 高
              streamId: "91ca42dfdf5111ed8d775db71f785691",
              dom: document.getElementById("rootdemo2"),
              accessToken: this.accessToken, // 可通过 getToken API获取后传入，也可直接传入
              streamType: "manyOfOne", //manyOfOne：共用一路
              endpoint: "vhost-entry.shuwen.com", // 域名，测试/正式 可能不同
            });
            this.player.on("load", function () {
              console.log("加载完成");
            });
            // error事件，用来监听一些错误
            this.player.on("error", function (error) {
              console.log("error", error);
            });
            // speakStart事件，用来监听主播开始说话
            this.player.on("speakStart", function () {
              // 开始说话后的一些操作，比如显示主播
              console.log("speakStart");
            });
            // speakEnd事件，用来监听主播说话结束
            this.player.on("speakEnd", function () {
              // 说话结束后的一些操作，比如隐藏主播
              console.log("speakEnd");
            });
          } else {
            console.error("登陆失败");
          }
        });
    },
    optionClick() {},
    Speaktext(){
       this.player.sendSpeak(this.text);
    },
    huida() {
      queAnswers({
        text: this.text,
      }).then((res) => {
        this.answer = res.value;
        this.player.sendSpeak(this.answer);
      });
    },
    createAdd() {
      this.loading = true;
      generate({
        text: this.text,
      }).then((res) => {
        this.timers = setInterval(() => {
          getDigitalMan(res.data).then((res1) => {
            if (res1.data[0].composeUrl) {
              this.loading = false;
              this.videoUrl = res1.data[0].composeUrl;
              clearInterval(this.timers);
              this.timers = null;
            }
          });
        }, 2000);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.demo2 {
  width: 100vw;
  height: 100vh;
  display: flex;
  min-width: 1200px;
  .demo_left {
    width: 500px;
    #rootdemo2 {
      width: 500px;
      height: 500px;
      position: relative;
      ::v-deep canvas {
        width: 100% !important;
        left: 15% !important;
      }
    }
    .videobox {
      width: 500px;
      height: 500px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .demo_right {
    flex: 1;
    overflow: auto;
    .righttop {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .ipt {
        height: 100%;
      }
      .el-button {
        height: 100%;
      }
    }
  }
}
</style>